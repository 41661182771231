<template>
  <section>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t('') }}</b-card-title>
        <b-button variant="outline-secondary" @click="addAddressModal=true,query={}" class="ll-itemBtn ll-btns">
          + {{ $t('新增地址') }}
        </b-button>
      </b-card-header>
      <b-card-body style="margin-top:-10px">
        <AdvanceTable ref="advanceTable" url="address_book/search"
                      :borderless='false' :hover="true" show-empty
                      :refreshTable="refreshTable" :on-row-clicked="setAddress" :selectable="true" :selectMode="'single'"
                      :columns="fields" :pageOptions="[50, 100, 200]" :defaultPageSize="50" :height="null"
        >
          <template #cell(person)="scope">
            <b-row v-if="scope.item.isDefault==true">
              <b-col cols="9">
                {{ scope.item.person }}
                <span
                    style="color:#d6d6d6">{{ scope.item.phone ? scope.item.phone.replace(/(\d{0})\d*(\d{4})/, "******$2") : '' }}</span>
              </b-col>
              <b-col cols="3" v-if="scope.item.isDefault==true">
                <b-badge variant="warning">{{ $t('默认') }}</b-badge>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="12">
                {{ scope.item.person }}
              </b-col>
              <b-col cols="12">
                <span
                    style="color:#d6d6d6"> {{ scope.item.phone ? scope.item.phone.replace(/(\d{0})\d*(\d{4})/, "******$2") : '' }}</span>
              </b-col>
            </b-row>
          </template>
          <template #cell(address)="scope">
            {{ scope.item.area }} {{ scope.item.district }}<br/>
            <span style="color:#d6d6d6">{{ scope.item.address }}</span>
          </template>
          <template #cell(companyName)="scope">
            {{ scope.item.company }}
          </template>
          <template #cell(operation)="scope">
            <feather-icon v-b-tooltip.hover :title="$t('Set as Default')" icon="StarIcon"
                          v-if="scope.item.isDefault==false" @click="isDefault(scope.item)" size="20"/>
            <feather-icon v-b-tooltip.hover :title="$t('Unset Default')" icon="StarIcon"
                          v-if="scope.item.isDefault==true" @click="isDefault(scope.item)" stroke="none" fill="red"
                          size="22"/>
            <feather-icon v-b-tooltip.hover :title="$t('Edit')" icon="Edit2Icon" @click="addressDetail(scope.item)"
                          class="ml-2" size="20"/>
            <feather-icon v-b-tooltip.hover :title="$t('Delete')" icon="Trash2Icon" id="trash"
                          @click="deleteAddress(scope.item)" class="ml-2" size="20"/>
          </template>
        </AdvanceTable>
      </b-card-body>
    </b-card>
    <b-button variant="primary" class="ll-itemBtn ll-btns" @click="getAddress" style="margin-left:45%">
      {{ $t('确认选择') }}
    </b-button>
    <b-modal cancel-variant="outline-secondary" size="lg" :title="$t('新增/修改地址')" @hidden="addAddressModal = false"
             v-model="addAddressModal" hide-footer>
      <validation-observer ref="rules" v-slot="{ invalid }">
        <b-card no-body>
          <b-card-header>
            <b-card-title>{{ $t('Address Information') }} <span style="font-size:12px;color:red">{{ $t('标 * 为必填项') }}</span>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-form-group class="ll-bform" label="" label-for="收货人/发件人姓名">
                  <validation-provider #default="{ errors }" name="收货人/发件人姓名"
                                       :rules="{required:true,max:20,regex:/^((?!\\).)*$/s}">
                    <label style="line-height: 30px">{{ $t("Shipper or Consignee Name") }}</label><span
                      style="color:red">*</span> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                    <b-form-input v-model="query.person"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group class="ll-bform" label="" label-for="">
                  <validation-provider #default="{ errors }" name="公司" :rules="{max:50,regex:/^((?!\\).)*$/s}">
                    <label style="line-height: 30px">{{ $t("Shipper or Consignee Company Name") }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                    <b-form-input v-model="query.company"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group class="ll-bform" label="" label-for="">
                  <validation-provider #default="{ errors }" name="电话"
                                       :rules="{required:true,min:8,max:20,regex:/^((?!\\).)*$/s}"
                  >
                    <label style="line-height: 30px">{{ $t("Shipper or Consignee Contact") }}</label><span style="color:red">*</span>
                    {{ $t("(No \"\\\", Maximum Length: 20)") }}
                    <b-form-input v-model="query.phone" type="number"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group class="ll-bform" label="" label-for="">
                  <validation-provider #default="{ errors }" name="地址"
                                       :rules="{required:true,max:225,regex:/^((?!\\).)*$/s}"
                  >
                    <label style="line-height: 30px">{{ $t("Shipper or Consignee Address") }}</label><span style="color:red">*</span>
                    {{ $t("(No \"\\\", Maximum Length: 225)") }}
                    <b-form-input v-model="query.address"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group class="ll-bform" label="" label-for="">
                  <validation-provider #default="{ errors }" name="城市"
                                       :rules="{regex:/(?:^|(?<= ))(KL|NT|HK|OI\d*$)(?:(?= )|$)/}">
                    <label style="line-height: 30px">{{ $t("Shipper or Consignee City") }}</label> {{ $t("(Only accept KL,NT,HK,OI)") }}
                    <b-form-input v-model="query.area"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group class="ll-bform" label="" label-for="">
                  <validation-provider #default="{ errors }" name="省" :rules="{max:50,regex:/^((?!\\).)*$/s}">
                    <label style="line-height: 30px">{{ $t("Shipper or Consignee District") }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                    <b-form-input v-model="query.district"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-button variant="primary" @click="confirmAdd" :disabled="invalid" class="ll-itemBtn ll-btns"
                  style="margin-left:45%">
          {{ $t('确认') }}
        </b-button>
      </validation-observer>
    </b-modal>
  </section>

</template>
<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, BIcon,
  BPagination, BFormSelect, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AdvanceTable from "@core/components/advanceTable/advanceTable.vue";

export default {
  components: {
    AdvanceTable,
    BBreadcrumb, BIcon,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BTable,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BPagination,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormRadio,
  },
  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      isBusy: false,
      pageOptions: [50, 100, 200, 500],
      page: '1',
      size: 50,
      totalSize: null,
      totalPage: null,
      addAddressModal: false,
      addressInfo: {},
      query: {},
      items: [],
      fields: [
        {key: 'person', modelName: "person", label: this.$t('Shipper or Consignee Name'), filtertype: "input"},
        {key: 'address', modelName:"address", label: this.$t('Shipper or Consignee Address'), filtertype: "input"},
        {key: 'company', modelName:"company", label: this.$t('Shipper or Consignee Company Name'), filtertype: "input"},
        {key: 'operation', label: this.$t('操作'), thStyle: {width: '150px'}},
      ],
      Authorization: JSON.parse(localStorage.getItem('userInfo')).token,
      refreshTable: false,
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    setAddress(val) {
      this.addressInfo = val
    },
    getAddress() {
      if (JSON.stringify(this.addressInfo) == '{}') {
        this.items.find(e => {
          if (e.isDefault == true) {
            this.addressInfo = e
          }
        })
      }
      console.info(this.addressInfo, '-')
      this.$emit('confirm', this.addressInfo, this.type)
    },
    deleteAddress(item) {
      this.$http.post('/address_book/delete', item).then(res => {
        if (res.data.code == 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.refreshTable = !this.refreshTable;
          this.loadList()
        }
      })
    },
    confirmAdd() {
      this.query.Authorization = JSON.stringify(this.Authorization)
      let url = this.query.id ? '/address_book/update' : '/address_book/add'
      this.$http.post(url, this.query).then(res => {
        if (res.data.code == 200) {
          this.addAddressModal = false
          this.refreshTable = !this.refreshTable;
          this.loadList()
        }
      })
    },
    isDefault(item) {
      item.isDefault = !item.isDefault
      this.query = item
      this.query.Authorization = JSON.stringify(this.Authorization)
      this.$http.post('/address_book/update', this.query).then(res => {
        if (res.data.code == 200) {
          this.addAddressModal = false
          this.refreshTable = !this.refreshTable;
          this.loadList()
        }
      })
    },
    addressDetail(item) {
      this.query = item
      this.addAddressModal = true
    },
    loadList() {
      this.$http.post('/address_book/search', {
        pag: this.page,
        size: this.size,
        Authorization: JSON.stringify(this.Authorization)
      }).then(res => {
        let info = res.data.data
        this.items = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
      })

    },
    handleChangePage(page) {
      this.page = page.toString()
      this.refreshTable = !this.refreshTable;
      this.loadList()
    },
    handlePageChange(active) {
      this.page = '1'
      this.size = active
      this.refreshTable = !this.refreshTable;
      this.loadList()
    },
  }
}
</script>