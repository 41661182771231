<template>
  <section>
    <div class="ll-topbox">
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }" >
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Delivery Order') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'orderList2' }" >
          {{ $t('Order List') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Create an order') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'intermediate-tip' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    </div>

    <validation-observer ref="rules">
      <b-card class="ll-card">
        <b-card-header>
          <b-card-title>{{ $t('Package Information') }}</b-card-title>
          <div class="ll-required">* {{ $t('Required Fields') }}</div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Reference Number')"
                  :rules="{max:20,regex:/^[a-zA-Z0-9-_]+$/}"
                  :custom-messages="{regex: 'Reference Number may only contain letters, numbers, dashes and underscores'}"
                >
                  <label class="">{{ $t('Reference Number') }}</label> {{ $t("(No Space, No \"\\\", Maximum Length: 20)") }}
                  <b-form-input v-model="pkg.reference_number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Number of Package')"
                  :rules="{required:true,integer:true, max_value:99}"
                >
                  <label class="ll-boldText">{{ $t('Number of Package') }}</label> {{ $t("(Maximum: 99, Only Accept Number)") }}
                  <b-form-input v-model="pkg.total_qty" type="number" min="1" max="10" @keydown="formatNumber($event)" @blur="pkg.total_qty=pkg.total_qty!=null?pkg.total_qty.replace(/^0(0+|\d+)|[^\d]+/g,''):''"/>
                  <small class="text-danger" v-if="pkg.total_qty==0">The Number of Package should be larger than 0</small>
                <small class="text-danger" v-else>{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Package Description')"
                  :rules="{max:50,regex:/^((?!\\).)*$/s}"
                >
                  <label class="">{{ $t('Package Description') }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                  <b-form-input v-model="pkg.description"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="COD Service"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('COD Service') }}</label>
                  <v-select
                    :options="payList"
                    @input="selPay"
                    label="key"
                    v-model="payment_method"
                  >
                    <template #option="{ key, des }">
                      <span class="ml-50 align-middle"> {{ key }}({{ des}})</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                label-for=""
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('COD Amount')"
                    :rules="{max:10,regex:/^((?!\\).)\d{0,7}(?:\.\d{0,2})?$/s}"
                >
                  <label class="">{{ $t('COD Amount')}}</label> {{ $t("(Only accept Number, 2 decimal places, Maximum Length: 10)") }}
                  <b-row>
                    <b-col>
                      <b-form-input v-model="pkg.cod_value" type="number" :readonly="readonly" @keydown="formatNumber($event)"/>
                    </b-col>
                    <b-col>
                      <v-select
                          :options="valueUnitList" @input="selValue1"
                          v-if="!readonly" v-model="codUnit1" label="currencyFrom"/>
                      <b-form-input :readonly="readonly" v-if="readonly"/>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                label-for=""
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('FOD Amount')"
                    :rules="{max:10,regex:/^((?!\\).)\d{0,7}(?:\.\d{0,2})?$/s}"
                >
                  <label class="">{{ $t('FOD Amount')}}</label> {{ $t("(Only accept Number, 2 decimal places, Maximum Length: 10)") }}
                  <b-row>
                    <b-col>
                      <b-form-input v-model="pkg.fod_value" type="number" disabled/>
                    </b-col>
                    <b-col>
                      <v-select
                          :options="valueUnitList" @input="selValue2"
                          v-model="codUnit2" label="currencyFrom" disabled="disabled"></v-select>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group
                  label-for="shipmentType"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Shipment Type')"
                  :rules="{required:true}"
                >
                  <label class="ll-boldText">{{ $t('Shipment Type') }}</label>
                  <v-select
                    :options="shipmentTypeList"
                    v-model="pkg.shipment_type"
                    placeholder=""
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4" v-if="!optional_shipment_value">
              <b-form-group label="">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Shipment Value')"
                  :rules="{required,max:10,regex:/^((?!\\).)\d{0,7}(?:\.\d{0,5})?$/s}"
                >
                  <label class="ll-boldText">{{ $t('Shipment Value') }}</label> {{ $t("(Only accept Number, 5 decimal places, Maximum Length: 10)") }}
                  <b-row>
                    <b-col>
                      <b-form-input v-model="pkg.goods_value" type="number" @keydown="formatNumber($event)"/>
                    </b-col>
                    <b-col>
                      <v-select :options="valueUnitList" @input="selValue" v-model="codUnit" label="currencyFrom"/>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4" v-if="optional_shipment_value">
              <b-form-group label="">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Shipment Value')"
                  :rules="{max:10,regex:/^((?!\\).)\d{0,7}(?:\.\d{0,5})?$/s}"
                >
                  <label class="">{{ $t('Shipment Value') }}</label> {{ $t("(Only accept Number, 5 decimal places, Maximum Length: 10)") }}
                  <b-row>
                    <b-col>
                      <b-form-input v-model="pkg.goods_value" type="number" @keydown="formatNumber($event)"/>
                    </b-col>
                    <b-col>
                      <v-select :options="valueUnitList" @input="selValue" v-model="codUnit" label="currencyFrom"/>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                label-for="netWeight"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Declared Gross Weight')"
                    :rules="{max:10,regex:/^((?!\\).)\d{0,7}(?:\.\d{0,2})?$/s}"
                >
                  <label class="">{{ $t('Declared Gross Weight') }}</label> {{ $t("(Only accept Number, 2 decimal places, Maximum Length: 10)") }}
                  <b-form-input v-model="pkg.actual_weight" placeholder="kg" type="number" @keydown="formatNumber($event)"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="3" v-if="false">
              <b-form-group
                :label="$t('Gross weight')"
                label-for="grossWeight"
              >
                <b-form-input placeholder="kg" v-model="pkg.gross_weight"/>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
              <b-col lg="4">
              <b-form-group
                label-for="dimension"
              >
                <validation-observer ref="dimension" v-slot="observer">
                  <validation-observer ref="oc">
                    <label>{{$t('Declared Dimension')}}</label> {{ $t("(Only accept Number, 2 decimal places, Maximum Length: 10)") }}
                    <b-row>
                      <b-col lg="4">
                        <validation-provider
                            v-slot="observer"
                            :name="$t('Length')"
                            :rules="{max:10,regex:/^((?!\\).)\d{0,7}(?:\.\d{0,2})?$/s}"
                        >
                          <b-form-input placeholder="cm" class="ll-ipt" v-model="pkg.length" type="number" @keydown="formatNumber($event)"/> X
                        </validation-provider>
                      </b-col>
                      <b-col lg="4">
                        <validation-provider
                            v-slot="observer"
                            :name="$t('Width')"
                            :rules="{max:10,regex:/^((?!\\).)\d{0,7}(?:\.\d{0,2})?$/s}"
                        >
                          <b-form-input placeholder="cm" class="ll-ipt" v-model="pkg.width" type="number" @keydown="formatNumber($event)"/> X
                        </validation-provider>
                      </b-col>
                      <b-col lg="4">
                        <validation-provider
                            v-slot="observer"
                            :name="$t('Height')"
                            :rules="{max:10,regex:/^((?!\\).)\d{0,7}(?:\.\d{0,2})?$/s}"
                        >
                          <b-form-input placeholder="cm" class="ll-ipt" v-model="pkg.height" type="number" @keydown="formatNumber($event)"/>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <small class="text-danger">{{ observer.errors['Length']?observer.errors['Length'][0]:'' }} {{ observer.errors['Width']?observer.errors['Width'][0]:'' }} {{ observer.errors['Height']?observer.errors['Height'][0]:'' }}</small>
                  </validation-observer>
                </validation-observer>

              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="" label-for="">
                <validation-provider #default="{ errors }" name="Remark" :rules="{max:50,regex:/^((?!\\).)*$/s}">
                  <label class="">{{ $t('Remark (Displayed on the Label)') }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                  <b-form-input v-model="pkg.remark"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                  :label="$t('Value Added Service (Displayed on the Label)')"
                  label-for="vas"
              >
                <v-select
                    :options="vasList"
                    :get-option-label="option => option.vasCode + '  - ' + JSON.parse(option.vasDesc)[this.$i18n.locale]"
                    placeholder=""
                    @input="updateVas"
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="4"  v-permission="['Choose Org']">
              <b-form-group
                :label="$t('Customer')"
                label-for="Customer"
              >
                <v-select
                    v-model.trim="customer"
                    :options="customerList"
                    label="value"
                    @search="queryCustomer"
                    @input="getCustomer"
                    placeholder="Search by OrgID/Cust. Name/FOX Acct. No."
                >
                </v-select>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col lg="4">
              <b-button variant="primary" @click="onItemDerail" class="ll-itemBtn ll-btns">
                + {{ $t('Item detail') }}
              </b-button>
            </b-col>
          </b-row>
          <!-- <b-button variant="primary" @click="onItemDerail" class="ll-itemBtn">
            + {{ $t('Item detail') }}
          </b-button> -->
          <!-- <b-table
            :items="items" :fields="fields"
            :borderless="false" :hover="true"
            v-if="isDetails"
          >
          </b-table> -->
          <div class="ll-subBtn" v-if="false">
            <b-button
              variant="info"
              @click="insertEvent()"
            >
              <span>{{ $t('Add') }}</span>
            </b-button>
          </div>
          <vxe-table
            v-if="isDetails"
            border
            show-overflow
            keep-source
            ref="xTable"
            max-height="400"
            :data="items"
            :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
            empty-text="No matching records found"
          >
            <vxe-table-column field="sku" title="Item code"  >
              <template #default="{ row }">
                <validation-provider
                  #default="{ errors }"
                  name="Item code"
                  rules="required"
                >
                  <b-form-input v-model="row.sku" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </template>
            </vxe-table-column>
            <vxe-table-column field="name" title="Item name" >
              <template #default="{ row }">
                <validation-provider
                  #default="{ errors }"
                  name="Item name"
                  rules="required"
                >
                  <b-form-input v-model="row.name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </template>
            </vxe-table-column>
            <!-- <vxe-table-column field="description" title="Item Description(Customs)"  >
              <template #default="{ row }">
                <b-form-input v-model="row.description" />
              </template>
            </vxe-table-column> -->
            <vxe-table-column field="qty" title="Qty"  >
              <template #default="{ row }">
                <b-form-input v-model="row.qty" type="number" @keydown="formatNumber($event)"/>
              </template>
            </vxe-table-column>
            <vxe-table-column field="unit_price" title="Unit price">
              <template #default="{ row }">
                <b-form-input v-model="row.unit_price" type="number" @keydown="formatNumber($event)"/>
              </template>
            </vxe-table-column>
            <vxe-table-column field="currency" title="Currency" >
              <template>
                <span>{{ currency }}</span>
                <!-- <b-form-input v-model="currency" readonly/> -->
              </template>
              <!-- <template #default="{ row }">
                <b-form-input v-model="row.currency" />
              </template> -->
            </vxe-table-column>
            <vxe-table-column title="Actives" width="100" show-overflow>
            <template #default="{ row }" >
              <vxe-button type="text" icon="" @click="insertEvent()">
                <feather-icon icon="PlusIcon" />
              </vxe-button>
              <vxe-button type="text" icon="" @click="removeEvent(row)">
                <feather-icon icon="Trash2Icon" />
              </vxe-button>
            </template>
          </vxe-table-column>
          </vxe-table>
        </b-card-body>
      </b-card>
      <b-card class="ll-card">
        <b-card-header>
          <b-card-title>{{ $t('Delivery Information') }}</b-card-title>
          <span @click="showAddressModal=true,open_type='consignee'"><feather-icon icon="BookIcon" /> {{$t('使用地址薄')}}</span>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consignee Name"
                  :rules="{required:true,max:20,regex:/^((?!\\).)*$/s}"
                >
                  <label class="ll-boldText">{{ $t('Consignee Name') }}</label> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                  <b-form-input v-model="receiver.name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                label-for="company"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Company')"
                  :rules="{max:50,regex:/^((?!\\).)*$/s}"
                >
                  <label class="">{{ $t('Consignee Company Name') }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                  <b-form-input v-model="receiver.company"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  :rules="{required:true,min:8,max:20,regex:/^((?!\\).)*$/s}"
                >
                  <label class="ll-boldText">{{ $t('Consignee Contact') }}</label> {{ $t("(No \"\\\", Maximum Length: 20)") }}
                  <b-form-input v-model="receiver.phone"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="3" v-if="false">
              <b-form-group
                :label="$t('Email')"
                label-for="email"
              >
                <b-form-input v-model="receiver.email"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Delivery method"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Delivery Method') }}</label>
                  <v-select :options="typeList" @input="selType" label="key" v-model="type">
                    <template slot="option" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      Order Delivery Type - {{ data.des }}
                    </template>
                    <template slot="selected-option" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      Order Delivery Type - {{ data.key }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                :label="$t('Estimated Delivery Date')"
                label-for="deliveryDate"
              >
                <flat-pickr
                  v-model="pkg.ead_date"
                  class="form-control ll-pickr"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d '}"
                />
              </b-form-group>
            </b-col>

            <!-- <b-col lg="3" class="ll-b" v-if="issp">
              <b-button variant="primary" @click="showMap">
                Select a self-pick location
              </b-button>
            </b-col> -->
          </b-row>
          <b-row v-if="ishd">
            <b-col lg="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Address"
                  :rules="{required:true,max:225,regex:/^((?!\\).)*$/s}"
                >
                  <label class="ll-boldText">{{ $t('Consignee Address') }}</label> {{ $t("(No \"\\\", Maximum Length: 225)") }}
                  <b-form-input v-model="receiver.address"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group class="">
                <validation-provider #default="{ errors }" :name="$t('District')" :rules="{max:50,regex:/^((?!\\).)*$/s}">
                  <label>{{ $t("Consignee District") }}</label> {{ $t("(No \"\\\", Maximum Length: 50)") }}
                  <b-form-input v-model="receiver.district"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group>
                <label class="">{{$t('Consignee City')}}</label>
                <validation-provider
                    #default="{ errors }"
                    name="Consignee City"
                    rules=""
                >
                  <v-select :options="cityList" v-model="receiver.city">
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="issp">
            <b-col lg="4" >
              <b-form-group
                label=""
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Service provider"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Self Pick Service Provider') }}</label>
                  <!-- <b-form-input v-model="receiver.address"/> -->
                  <v-select :options="proList" label="agent" @input="selPro" v-model="pro"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4" >
              <b-form-group
                label=""
                label-for="district"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location code"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Self Pick Location Code') }}</label>
                  <!-- <b-form-input v-model="receiver.district"/> -->
                  <v-select :options="locCodeList" label="locationID" @input="selCode" v-model="locCode"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4" >
              <b-form-group
                :label="$t('Self Pick Location Name')"
                label-for="area"
              >
                <b-form-input v-model="locName"/>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row v-if="issp">
            <b-col lg="6"></b-col>
            <b-col lg="6">
              <div class="ll-map" ref="map"></div>
            </b-col>
          </b-row> -->
        </b-card-body>
      </b-card>
      <!-- <b-card-actions
        title="Sender info"
        action-collapse
        :collapsed="collapsed"
        class="ll-card"
      > -->
      <b-card class="ll-card">
        <b-card-header>
          <b-card-title>{{ $t('Shipper Information') }}</b-card-title>
          <span @click="showAddressModal=true,open_type='sender'"><feather-icon icon="BookIcon" /> {{$t('使用地址薄')}}</span>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label=""
                label-for="senderName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Sender Name"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Shipper Name') }}</label>
                  <b-form-input v-model="sender.name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                :label="$t('Shipper Company')"
                label-for="senderCompany"
              >
                <b-form-input v-model="sender.company" :readonly="can[216]"/>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                label=""
                label-for="senderPhone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Shipper Contact"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Shipper Contact') }}</label>
                  <b-form-input v-model="sender.phone" :readonly="can[216]"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label=""
                label-for="senderAddress"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Shipper Address"
                  rules="required"
                >
                  <label class="ll-boldText">{{ $t('Shipper Address') }}</label>
                  <b-form-input v-model="sender.address" :readonly="can[216]"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
              >
                <validation-provider
                    #default="{ errors }"
                    name="Shipper City"
                    rules=""
                >
                  <label class="">{{$t('Shipper City')}}</label>
                  <b-form-input v-model="sender.city"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                :label="$t('Shipper District')"
                label-for="senderDistrict"
              >
                <b-form-input v-model="sender.district"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      <!-- </b-card-actions> -->
      </b-card>
      <!-- submit btn -->
      <div class="ll-subBtn">
        <b-button
          variant="info"
          @click.prevent="validationForm"
        >
          {{ $t('Submit') }}
        </b-button>
      </div>
    </validation-observer>
     <b-modal cancel-variant="outline-secondary" size="lg" :title="$t('从地址簿选择')" @hidden="showAddressModal = false" v-model="showAddressModal" hide-footer>
      <Address @confirm="getInfo" :type="open_type" />
    </b-modal>
  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BFormTextarea,
  BRow, BCol, BCard, BFormGroup, BFormInput, BBadge, BTable,
  BButton, BSidebar, BInputGroupPrepend, BInputGroup,
  BCardHeader, BCardTitle, BCardBody, BImg,
  BPagination, BFormSelect, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { debounce } from "@/libs/fun.js"
import permission from '@core/directives/permission/index.js'
import Address from './address.vue'
import {formatNumber} from "@core/utils/filter";
import {required} from '@validations';

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BTable,
    BSidebar,
    vSelect,Address,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BPagination,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormCheckbox,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BCardActions,
    flatPickr,
  },
  directives: {
    permission,
  },
  data() {
    return {
      required,
      isDetails: false,
      showAddressModal:false,
      open_type:'',
      items: [
        { sku: null, name: null, qty: null, unit_price: null, currency: null}
      ],
      fields: [
        { key: 'title', label: this.$t('Item name') },
        { key: 'productId', label: this.$t('Item Code') },
        { key: 'quantity', label: this.$t('Item description(customs)') },
        { key: 'unitPrice', label: this.$t('Qty') },
        { key: 'currency', label: this.$t('Unit price') },
        // { key: 'hsCode', label: 'HSCode' },
        // { key: 'customDesc', label: 'COO' },
        // { key: 'origin', label: 'Origin' },
        // { key: 'a', label: 'Hazmat' },
        // { key: 'd', label: 'Fragile' },
        { key: 's', label: this.$t('Seller code') },
      ],
      collapsed: false,
      pkg: {
        reference_number: null,
        payment_method: null,
        shipment_type: null,
        total_qty: null,
        // declared_value: null,
        // declared_value_currency: null,
        ead_date: null,
        actual_weight: null,
        description: null,
        cod_value: null,
        cod_currency: 'HKD',
        goods_value: null,
        gross_weight: null,
        remark: null,
        vas: null,
        width: null,
        length: null,
        height: null,
        orgId: null,
        fod_value: null,
        fod_value_currency: 'HKD',
      },
      receiver: {
        name: null,
        phone: null,
        company: null,
        email: null,
        address: null,
        district: null,
        city: null,
      },
      sender: {
        name: null,
        phone: null,
        company: null,
        address: null,
        city: null,
        district: null,
      },
      service: {
        service_type: null,
        delivery_instruction: null,
      },
      payList: [
        { key: 'PP', des: 'Prepaid' },
        { key: 'COD', des: 'Cash on delivery' },
      ],
      payment_method: null,
      valueUnitList: [],
      valueUnitList1: [],
      isShowmap: false,
      longitude: 114.132294,
      latitude: 22.360371,
      typeList: [
        { key: 'HD', des: 'Home Delivery' },
        { key: 'SP', des: 'Self Pick' }
      ],
      type: null,
      currency: null,
      ishd: false,
      issp: false,
      readonly: false,
      proList: null,
      pro: null,
      locCodeList: [],
      locCode: null,
      locName: null,
      cityList: ['HK', 'KL', 'NT', 'OI'],
      codUnit: 'HKD',
      codUnit1: 'HKD',
      codUnit2: 'HKD',
      customerList: [],
      customer: '',
      vasList: [],
      shipmentTypeList: ['Non-document', 'Document'],
      shipment_type:'',
      optional_shipment_value: false,
      userInfo: JSON.parse(localStorage.getItem('userInfo')),

    }
  },
  computed: {},
  watch: {
    // 'pkg.cod_value'(val) {
    //   if (val === null || val === '') {
    //     this.valueUnitList1 = []
    //   } else {
    //     this.valueUnitList1 = ['HKD', 'CNY', 'USD', 'EUR']
    //   }
    // }
    'receiver.phone'(val) {
      if (val.length > 20) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Phone must not exceed 20 digits',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    'sender.phone'(val) {
      if (val.length > 20) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Phone must not exceed 20 digits',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    'pkg.shipment_type'(val) {
      if (val === 'Document') {
        this.optional_shipment_value = true
      } else {
        this.optional_shipment_value = false
      }
    },
  },
  created() {},
  mounted() {
    // this.initMaps()
    this.queryAgentList()
    this.queryCustomer()
    this.queryCurrency()
    this.getVas()
    this.getCustomerDetail()
  },
  methods: {
    formatNumber,
    // queryType() {
    //   this.$http.get('/admin/settings/organization/getOrgType').then(res=>{
    //     if (!res.data.data) {
    //       this.readonly = true
    //     }
    //   })
    // },
    getInfo(item,type){
      this.showAddressModal = false
      console.info(item,type)
      if (type == 'sender') {
        this.sender={
          name: item.person,
          phone: item.phone,
          company: item.company,
          address: item.address,
          city: item.area,
          district: item.district,
        }
      }else{
        this.receiver={
          name: item.person,
          phone: item.phone,
          company: item.company,
          address: item.address,
          city: item.area,
          district: item.district,
        }
      }
    },
    queryCustomer(d) {
      let condition = d ? d : null
      this.$http.get('/admin/settings/organization/getCacheOrganization?condition=' + condition).then(res => {
        this.customerList = res.data.data
      })
    },
    getCustomer(s) {
      this.customer = s.value
      this.pkg.orgId = s.key
    },
    getCustomerDetail() {
      // if have permission 216, then get customer detail


        this.$http.get('/admin/settings/organization/getOrganizationByUserId?userId=' + this.userInfo.userId).then(res => {
          // console.log(res.data.data)
          const data = res.data.data;
          let condition = {
            id: parseInt(data.id)
          }
          this.sender.address = data.address
          this.sender.company = data.name

          this.$http.post('admin/settings/organization/getInfoById', condition).then(res2 => {
            // console.log(res.data.data)
            const info = res2.data.data
            // console.log(info)
            this.sender.city = info.pickUpArea
            this.sender.district = info.pickUpDistrict
          })
        });
    },
    getVas() {
      this.$http.get('/configVases/findAllConfigVasesByIsEnabled').then(res => {
        this.vasList = res.data.data
      })
    },
    updateVas(t) {
      if (t === null) {
        this.pkg.vas = null
        return
      }
      this.pkg.vas = t.vasCode;
    },
    addOrder: debounce(function() {
      if (!this.pkg.cod_value) {
        this.pkg.cod_currency = null
      }
      if (!this.pkg.fod_value) {
        this.pkg.fod_value_currency = null
      }
      // if ((this.pkg.cod_value && !this.pkg.cod_currency)
      //  || (!this.pkg.cod_value && this.pkg.cod_currency)
      //  || (this.pkg.fod_value && !this.pkg.fod_value_currency)
      //  || (!this.pkg.fod_value && this.pkg.fod_value_currency)){
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'amount and currency both values are required at the same time',
      //       icon: 'XIcon',
      //       variant: 'danger',
      //     },
      //   })
      //   return
      // }
      const obj = {}
      obj.service = this.service
      obj.package = this.pkg
      obj.sender = this.sender
      obj.receiver = this.receiver
      const $table = this.$refs.xTable
      // console.log($table)
      if ($table !== undefined) {
        const arr = []
        $table.tableData.map(i =>{
          const obj1 = {}
          obj1.name = i.name
          obj1.sku = i.sku
          obj1.description = i.description
          obj1.qty = i.qty
          obj1.unit_price = i.unit_price
          obj1.currency = this.currency
          arr.push(obj1)
        })
        obj.items = arr
        // obj.merchant_code = null
        this.createOrder(obj)
      } else {
        obj.items = []
        this.createOrder(obj)
      }
    }, 1000),
    createOrder(obj) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Submitting, please wait...",
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
      this.$http.post('/lmd/createonedo', obj).then(res => {
        if (res.data.code === '200') {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Create order Success',
          //     icon: 'CheckIcon',
          //     variant: 'success',
          //   },
          // })
          this.$router.push({ name: 'orderList2' })
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: res.data.message,
          //     icon: 'XIcon',
          //     variant: 'danger',
          //   },
          // })
        }
      })
    },
    validationForm() {
      if (this.pkg.total_qty == 0) {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The Number of Package should be larger than 0',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
      }
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.addOrder()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    showMap() {
      this.isShowmap = !this.isShowmap
    },
    initMaps() {
       const maps = new google.maps.Map(this.$refs.map, {
        zoom: 8,
        center: { lat: this.latitude, lng: this.longitude },
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        language: 'zh_cn',
      })
      // this.marker = new google.maps.Marker({
      //   position: { lat: this.latitude, lng: this.longitude },
      //   map: this.maps,
      //   title: 'k',
      // })
      this.setMarkers(maps)
    },
    setMarkers(map) {
      const beaches = [
        ["Bondi Beach", 115, 23.259052, 4],
        ["Cronulla Beach", 116, 24.259052, 4],
        // ["Coogee Beach", -33.923036, 151.259052, 5],
        // ["Cronulla Beach", -34.028249, 151.157507, 3],
        // ["Manly Beach", -33.80010128657071, 151.28747820854187, 2],
        // ["Maroubra Beach", -33.950198, 151.259302, 1],
      ]
      const image = {
        url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        size: new google.maps.Size(20, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 32),
      }
      const shape = {
        coords: [1, 1, 1, 20, 18, 20, 18, 1],
        type: "poly",
      }
      for (let i = 0; i < beaches.length; i++) {
        const beach = beaches[i];
        new google.maps.Marker({
          position: { lat: beach[1], lng: beach[2] },
          map,
          // icon: image,
          label: {
            text: "K",
            fontFamily: "Material Icons",
            color: "#ffffff",
            fontSize: "14px",
          },
          shape: shape,
          title: beach[0],
          zIndex: beach[3],
        });
      }
    },
    async insertEvent (row) {
      const $table = this.$refs.xTable
      const record = {}
      const { row: newRow } = await $table.insertAt(record, row)
      await $table.setActiveCell(newRow)
    },
    removeEvent (row) {
      this.box = ''
      this.$bvModal
      .msgBoxConfirm('Please confirm that you want to delete .', {
        title: 'Handle',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if (value) {
          this.box = value
          this.$refs.xTable.remove(row)
        }
      })
    },
    onItemDerail() {
      this.isDetails = !this.isDetails
    },
    selPay(d) {
      this.pkg.payment_method = d.key
      this.payment_method = `${d.key}(${d.des})`
      if (d.key === 'COD') {
        this.readonly = false
      } else {
        this.readonly = true
        // this.pkg.payment_method = null
      }
    },
    selType(d) {
      this.service.service_type = d.key
      this.type = `${d.des}`
      if (d.key === 'HD') {
        this.ishd = true
        this.issp = false
      } else {
        this.issp = true
        this.ishd = false
      }
    },
    queryCurrency() {
      this.$http.get('currency/selectAll').then(res=>{
        this.valueUnitList = res.data.data
      })
    },
    selValue(d) {
      // this.pkg.declared_value_currency = d
      if (d) {
        this.currency = d.currencyFrom
        this.codUnit = d.currencyFrom
      } else {
        this.currency = null
        this.codUnit = null
      }
    },
    selValue1(d) {
      if (d) {
        this.codUnit1 = d.currencyFrom
        this.pkg.cod_currency = d.currencyFrom
      } else {
        this.codUnit1 = null
        this.pkg.cod_currency = null
      }
    },
    selValue2(d) {
      if (d) {
        this.codUnit2 = d.currencyFrom
        this.pkg.fod_value_currency = d.currencyFrom
      } else {
        this.codUnit2 = null
        this.pkg.fod_value_currency = null
      }
    },
    queryAgentList() {
      this.$http.get('selfpickLocation/getAgentList').then(res=>{
        this.proList = res.data.data
      })
    },
    selPro(d) {
      this.pro = d.agent
      this.locCode = null
      this.locName = null
      this.queryLocCode()
    },
    queryLocCode() {
      this.$http.get('selfpickLocation/getListBySearch', {params: {agent:this.pro}}).then(res=>{
        this.locCodeList = res.data.data
      })
    },
    selCode(d) {
      this.locCode = d.locationID
      this.service.delivery_instruction = d.locationID
      this.queryLocName()
    },
    queryLocName() {
      this.$http.get('selfpickLocation/getListBySearch', {params: {agent:this.pro, locationID:this.locCode}}).then(res=>{
        this.locName = res.data.data[0].storename
        this.receiver.address = res.data.data[0].storename
      })
    },
    selCity(d) {
      this.receiver.city = d
    },
    can(permissions) {
      const arr = []
      this.userInfo.buttonPermissions.map(i=>{
        arr.push(i.id)
      })
      const roles = arr
      if (permissions && permissions instanceof Array) {
        if (permissions.length > 0) {
          const permissionRoles = permissions

          const hasPermission = roles.some(role => {
            return permissionRoles.includes(role)
          })

          return hasPermission
        }
      } else {
        throw new Error(`need roles! Like v-permission="['admin','editor']"`)
      }
    }
  },
}
</script>

<style scoped>
.ll-btns{
  margin-top: 18px;
}
.ll-ipt{
  display: inline-block;
  width: 85%;
  margin-right: 0.5rem;
}
.ll-subBtn{
  text-align: right;
  height: 50px;
}
</style>
<style>
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
.ll-boldText:after{
  content:" *";
  color: red;
}
.ll-b{
  display: flex;
  align-items: center;
}
.ll-card{
  margin-bottom: 1rem;
}
.ll-map{
  width: 100%;
  height: 230px;
  margin-bottom: 10px;
}
.ll-itemBtn{
  margin-bottom: 10px;
}
</style>
<style>
.vxe-modal--body{
  height: auto;
  overflow: unset ;
}
.vxe-modal--wrapper.type--alert .vxe-modal--body,
.vxe-modal--wrapper.type--alert .vxe-modal--body .vxe-modal--content,
.vxe-modal--wrapper.type--confirm .vxe-modal--body,
.vxe-modal--wrapper.type--confirm .vxe-modal--body .vxe-modal--content,
.vxe-modal--wrapper.type--modal .vxe-modal--body,
.vxe-modal--wrapper.type--modal .vxe-modal--body
.vxe-modal--content{
  overflow: unset;
}
.vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell, .vxe-table--render-default .vxe-footer--column.col--ellipsis>.vxe-cell, .vxe-table--render-default .vxe-header--column.col--ellipsis>.vxe-cell {
  max-height: 60px;
  padding: 5px;
}
</style>